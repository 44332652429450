import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-column p-1"
}
const _hoisted_2 = { class: "p-0 m-0 text-truncate" }
const _hoisted_3 = { class: "card-body p-1" }
const _hoisted_4 = { class: "d-flex justify-content-start mb-1" }
const _hoisted_5 = { class: "pe-1 border-end" }
const _hoisted_6 = { class: "px-1 border-end" }
const _hoisted_7 = { class: "px-1 flex-fill border-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.planung != null && _ctx.planung.listePlanungResource != null && _ctx.planung.listePlanungResource.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.planung.listePlanungResource, (plan) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["card border mb-1 rounded-0 small", _ctx.getProdAuftragBorderColor(plan.gesamtanzahl, plan.gesamtanzahlIst)])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass(["card-header rounded-0 p-1", _ctx.getProdAuftragBgColor(plan.gesamtanzahl, plan.gesamtanzahlIst)])
            }, [
              _createElementVNode("p", _hoisted_2, _toDisplayString(plan.artikel), 1)
            ], 2),
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, _toDisplayString(plan.artikelnr), 1),
                _createElementVNode("div", _hoisted_6, _toDisplayString(plan.landnr) + " " + _toDisplayString(plan.landkz), 1),
                _createElementVNode("div", _hoisted_7, _toDisplayString(plan.kommissionid), 1),
                _createElementVNode("div", {
                  class: _normalizeClass(["ps-2", _ctx.getProdAuftragTextColor(plan.gesamtanzahl, plan.gesamtanzahlIst)])
                }, _toDisplayString(plan.gesamtanzahlIst) + "/" + _toDisplayString(plan.gesamtanzahl), 3)
              ])
            ])
          ], 2))
        }), 256))
      ]))
    : _createCommentVNode("", true)
}