import { ActionContext, ActionTree } from "vuex";
import LagerService from '@/service/LagerService'

import { RootState } from '@/store';

import { State } from "./state"

import { Mutations, MutationTypes } from "./mutations";

import { Actions as CommonActions, ActionTypes as CommonActionTypes } from "../../Common/actions";
import { Maschine } from "@/models/MaschinenModels";



export enum ActionTypes {
  GetMaschinen = "GETMASCHINEN",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetMaschinen](context: ActionArguments): Promise<void>,
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetMaschinen]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.maschinenLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return LagerService.getLagerMaschinen()
      .then(res => {
        status.maschinenLoading = false
        dispatch(CommonActionTypes.SetStatus, status);

        const result: Array<Maschine> = res.data
        commit(MutationTypes.GetMaschinenSuccess, result)
      })
      .catch(error => {
        status.maschinenLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.maschinenErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.maschinenErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.maschinenErrorMsg = error
        }
        
        sessionStorage.removeItem("maschinen");
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
}
