import { ActionContext, ActionTree } from "vuex";
import PpsService from '@/service/PpsService'

import { RootState } from '@/store';

import { State } from "./state"

import { Mutations, MutationTypes } from "./mutations";

import { Actions as CommonActions, ActionTypes as CommonActionTypes } from "../../Common/actions";
import { Maschine } from "@/models/MaschinenModels";
import { PlanungMaschinenHeute } from "@/models/PlanungModels";
import { Produktionsstatus, Maschinenplanung } from "@/models/ProduktionsstatusModels";
import LagerService from "@/service/LagerService";



export enum ActionTypes {
  GetProduktionsstatus = "GETPRODUKTIONSSTATUS",
  UpdateGetProduktionsstatus = "UPDATEGETPRODUKTIONSSTATUS",
  getMaschinenPlanungHeute = "GETMASCHINENPLANUNGHEUTE",
  updateMaschinenPlanungHeute = "UPDATEMASCHINENPLANUNGHEUTE"
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetProduktionsstatus](context: ActionArguments): Promise<void>,
  [ActionTypes.UpdateGetProduktionsstatus](context: ActionArguments): Promise<void>,
  [ActionTypes.getMaschinenPlanungHeute](context: ActionArguments): Promise<void>,
  [ActionTypes.updateMaschinenPlanungHeute](context: ActionArguments): Promise<void>,
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetProduktionsstatus]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.produktionsstatusLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return PpsService.getProduktionsstatus()
      .then(res => {
        status.produktionsstatusLoading = false
        status.produktionsstatusFetched = true
        dispatch(CommonActionTypes.SetStatus, status);

        const result: Array<Produktionsstatus> = res.data
        commit(MutationTypes.GetProduktionsstatusSuccess, result)
      })
      .catch(error => {
        status.produktionsstatusLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.maschinenErrorMsg = "Es konnt keine Verbindung zum Server hergestellt werden."
        } else if (error.response?.status == 403){
          status.maschinenErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.maschinenErrorMsg = error
        }
        
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateGetProduktionsstatus]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;

    return PpsService.getProduktionsstatus()
      .then(res => {
        status.produktionsstatusFetched = true
        dispatch(CommonActionTypes.SetStatus, status);

        const result: Array<Produktionsstatus> = res.data
        commit(MutationTypes.GetProduktionsstatusSuccess, result)
      })
      .catch(error => {
        if (error.message === 'Network Error') {
          status.maschinenErrorMsg = "Es konnt keine Verbindung zum Server hergestellt werden."
        } else if (error.response?.status == 403){
          status.maschinenErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.maschinenErrorMsg = error
        }
        
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  
  async [ActionTypes.getMaschinenPlanungHeute]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.maschinenstatusLoading = true;
    status.maschinenstatusErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    const maschineids = [1, 6, 8, 10, 12, 24]

    try {
      const res = await PpsService.getProduktionsstatus();
      let result = new Array<Maschinenplanung>();
      for(let i = 0; i < res.data.length; i++) {
        if (maschineids.includes(res.data[i].maschinenid)) {
          const maschine: Maschinenplanung = res.data[i]

          try {
            const resp = await LagerService.getMaschinenPlanungHeuteListeartikel(maschine.maschinename)
            maschine.planung = resp.data
          }
          catch (err: any) {
            // maschine.planung = new Array<PlanungMaschinenHeute>();
          }
          
          result.push(maschine)
        }
      }
      
      commit(MutationTypes.SetMaschinenplanungHeute, result)
      status.maschinenstatusLoading = false
      dispatch(CommonActionTypes.SetStatus, status);
    } catch (error: any) {
      status.maschinenstatusLoading = false
      if (error.message === 'Network Error') {
        status.maschinenstatusErrorMsg = "Es konnt keine Verbindung zum Server hergestellt werden."
      } else if (error.response?.status == 403){
        status.maschinenstatusErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
      } else {
        status.maschinenstatusErrorMsg = error
      }
      
      dispatch(CommonActionTypes.SetStatus, status);
    }

    return
  },

  async [ActionTypes.updateMaschinenPlanungHeute]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.maschinenstatusErrorMsg = "";
    dispatch(CommonActionTypes.SetStatus, status);

    const maschineids = [1, 6, 8, 10, 12, 24]

    try {
      const res = await PpsService.getProduktionsstatus();
      
      let result = new Array<Maschinenplanung>();
      for(let i = 0; i < res.data.length; i++) {
        if (maschineids.includes(res.data[i].maschinenid)) {
          const maschine: Maschinenplanung = res.data[i]

          try {
            const resp = await LagerService.getMaschinenPlanungHeuteListeartikel(maschine.maschinename)
            maschine.planung = resp.data
          }
          catch (err: any) {
            // maschine.planung = new Array<PlanungMaschinenHeute>();
          }
          
          result.push(maschine)
        }
      }
      
      commit(MutationTypes.SetMaschinenplanungHeute, result)
    } catch (error: any) {
      if (error.message === 'Network Error') {
        status.maschinenstatusErrorMsg = "Es konnt keine Verbindung zum Server hergestellt werden."
      } else if (error.response?.status == 403){
        status.maschinenstatusErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
      } else {
        status.maschinenstatusErrorMsg = error
      }
      
      dispatch(CommonActionTypes.SetStatus, status);
    }
  },
}
