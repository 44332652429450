import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

import Home from "@/components/Views/Home.vue"
import Herstellung from '@/components/Herstellung/Views/Herstellung.vue'
import Material from '@/components/Materialbestellung/Views/Material.vue'
import MaterialFlaschen from '@/components/Materialbestellung/Views/MaterialFlaschen.vue'
import MaterialKartons from '@/components/Materialbestellung/Views/MaterialKartons.vue'
import MaterialKapseln from '@/components/Materialbestellung/Views/MaterialKapseln.vue'
import Produktion from '@/components/Produktion/Views/Produktion.vue'
import Maschinen from '@/components/Produktion/Views/Maschinen.vue'
import Verwaltung from '@/components/Verwaltung/Views/Verwaltung.vue'
import Mitarbeiter from '@/components/Mitarbeiter/Views/Mitarbeiter.vue'
import Lager from '@/components/Lager/Views/Lager.vue'
import MaterialLager from '@/components/Lager/Views/MaterialLager.vue'
import ProduktionLager from '@/components/Lager/Views/ProduktionLager.vue'
import HerstellungLager from '@/components/Lager/Views/HerstellungLager.vue'
import Login from '@/components/Archiv/Views/Login.vue'
import Logout from '@/components/Archiv/Views/Logout.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '/herstellung',
    name: 'herstellung',
    component: Herstellung
  },
  {
    path: '/Material',
    name: 'material',
    component: Material
  },
  {
    path: '/material/flaschen',
    name: 'materialflaschen',
    component: MaterialFlaschen
  },
  {
    path: '/material/kartons',
    name: 'materialkartons',
    component: MaterialKartons
  },
  {
    path: '/material/kapseln',
    name: 'materialkapseln',
    component: MaterialKapseln
  },
  {
    path: '/produktion',
    name: 'produktion',
    component: Produktion
  },
  {
    path: '/produktion/maschinen',
    name: 'maschinen',
    component: Maschinen
  },
  {
    path: '/verwaltung',
    name: 'verwaltung',
    component: Verwaltung
  },
  {
    path: '/mitarbeiter',
    name: 'mitarbeiter',
    component: Mitarbeiter
  },
  {
    path: '/lager/fertigwaren',
    name: 'lager',
    component: Lager
  },
  {
    path: '/lager/material',
    name: 'materialLager',
    component: MaterialLager
  },
  {
    path: '/lager/produktion',
    name: 'produktionLager',
    component: ProduktionLager
  },
  {
    path: '/lager/herstellung',
    name: 'herstellungLager',
    component: HerstellungLager
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach( (to, from, next) => {
  const publicPages = ['/login', '/logout'];
  const authRequired = !publicPages.includes(to.path);

  let loggedIn = false;

  if ((localStorage.getItem('authUser'))) {
    loggedIn = true
  }

  if (authRequired && !loggedIn) {
    return next('/login')
  }

  next();
});

export default router
