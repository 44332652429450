import { ActionContext, ActionTree } from "vuex";
import PpsService from '@/service/PpsService'
import LagerService from '@/service/LagerService'

import { RootState } from '@/store';

import { State } from "./state"

import { Mutations, MutationTypes } from "./mutations";

import { Actions as CommonActions, ActionTypes as CommonActionTypes } from "../../Common/actions";
import { Flasche, Kapsel, Karton } from "@/models/PpsMaterialModels";
import { Materialartikel } from "@/models/LagerMaterialModels";



export enum ActionTypes {
  GetFlaschenBestand = "GETFLASCHENBESTAND",
  UpdateGetFlaschenBestand = "UPDATEGETFLASCHENBESTAND",
  GetKartonsBestand = "GETKARTONSBESTAND",
  UpdateGetKartonsBestand = "UPDATEGETKARTONSBESTAND",
  GetKapselnBestand = "GETKAPSELNBESTAND",
  UpdateGetKapselnBestand = "UPDATEGETKAPSELNBESTAND",
  GetMaterialartikelFlaschen = "GETMATERIALARTIKELFLASCHEN",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetFlaschenBestand](context: ActionArguments): Promise<void>,
  [ActionTypes.UpdateGetFlaschenBestand](context: ActionArguments): Promise<void>,
  [ActionTypes.GetKartonsBestand](context: ActionArguments): Promise<void>,
  [ActionTypes.UpdateGetKartonsBestand](context: ActionArguments): Promise<void>,
  [ActionTypes.GetKapselnBestand](context: ActionArguments): Promise<void>,
  [ActionTypes.UpdateGetKapselnBestand](context: ActionArguments): Promise<void>,
  [ActionTypes.GetMaterialartikelFlaschen](context: ActionArguments): Promise<void>,
}



export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetFlaschenBestand]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.flaschenBestandLoading = true
    status.flaschenBestandErrorMsg = ""
    dispatch(CommonActionTypes.SetStatus, status);

    return PpsService.getAlleFlaschenBestand()
      .then(res => {
        status.flaschenBestandLoading = false
        dispatch(CommonActionTypes.SetStatus, status);

        const result: Array<Flasche> = res.data
        commit(MutationTypes.GetFlaschenbestandSuccess, result)
      })
      .catch(error => {
        status.flaschenBestandLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.flaschenBestandErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.flaschenBestandErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.flaschenBestandErrorMsg = error
        }
        
        sessionStorage.removeItem("materialFlaschen");
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateGetFlaschenBestand]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.flaschenBestandErrorMsg = ""
    dispatch(CommonActionTypes.SetStatus, status);

    return PpsService.getAlleFlaschenBestand()
      .then(res => {
        dispatch(CommonActionTypes.SetStatus, status);

        const result: Array<Flasche> = res.data
        commit(MutationTypes.GetFlaschenbestandSuccess, result)
      })
      .catch(error => {
        // console.log(error);
        if (error.message === 'Network Error') {
          status.flaschenBestandErrorMsg = "Es konnt keine Verbindung zum Server hergestellt werden."
        } else if (error.response?.status == 401){
          status.flaschenBestandErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.flaschenBestandErrorMsg = error
        }
        
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetKartonsBestand]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.kartonsBestandErrorMsg = ""
    status.kartonsBestandLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return PpsService.getAlleKartonsBestand()
      .then(res => {
        status.kartonsBestandLoading = false
        dispatch(CommonActionTypes.SetStatus, status);

        const result: Array<Karton> = res.data
        commit(MutationTypes.GetKartonsbestandSuccess, result)

        // dispatch(ActionTypes.GetMaterialartikelFlaschen)
        //   .then(() => {
        //     commit(MutationTypes.SetAnzahlFlaschen, undefined)
        //   })
      })
      .catch(error => {
        status.kartonsBestandLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.kartonsBestandErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.kartonsBestandErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.kartonsBestandErrorMsg = error
        }
        
        sessionStorage.removeItem("materialKartons");
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateGetKartonsBestand]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.kartonsBestandErrorMsg = ""
    dispatch(CommonActionTypes.SetStatus, status);

    return PpsService.getAlleKartonsBestand()
      .then(res => {
        dispatch(CommonActionTypes.SetStatus, status);

        const result: Array<Karton> = res.data
        commit(MutationTypes.GetKartonsbestandSuccess, result)
      })
      .catch(error => {
        // console.log(error);
        if (error.message === 'Network Error') {
          status.kartonsBestandErrorMsg = error.message
        } else if (error.response?.status == 403){
          status.kartonsBestandErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.kartonsBestandErrorMsg = error
        }
        
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetKapselnBestand]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.kapselnBestandErrorMsg = "";
    status.kapselnBestandLoading = true;
    dispatch(CommonActionTypes.SetStatus, status);

    return PpsService.getAlleKapselnBestand()
      .then(res => {
        status.kapselnBestandErrorMsg = "";
        const result: Array<Kapsel> = res.data
        commit(MutationTypes.GetKapselnbestandSuccess, result)
      })
      .catch(error => {
        // console.log(error);
        if (error.message === 'Network Error') {
          status.kapselnBestandErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.kapselnBestandErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.kapselnBestandErrorMsg = error
        }
        
        sessionStorage.removeItem("materialKapseln");
      })
      .finally(() => {
        status.kapselnBestandLoading = false
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateGetKapselnBestand]({ commit, dispatch, rootGetters }) {
    
    return PpsService.getAlleKapselnBestand()
    .then(res => {
      const result: Array<Kapsel> = res.data
      commit(MutationTypes.GetKapselnbestandSuccess, result)
    })
    .catch(error => {
      const status = rootGetters.status;
      status.kapselnBestandLoading = false
      // console.log(error);
      if (error.message === 'Network Error') {
        status.kapselnBestandErrorMsg = error.message
      } else if (error.response?.status == 401){
        status.kapselnBestandErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
      } else {
        status.kapselnBestandErrorMsg = error
      }
      
      sessionStorage.removeItem("materialKapseln");
      dispatch(CommonActionTypes.SetStatus, status);
    })
  },

  async [ActionTypes.GetMaterialartikelFlaschen]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.materialartikelFlaschenLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return LagerService.getAlleMaterialartikelFlaschen()
      .then(res => {
        status.materialartikelFlaschenLoading = false
        dispatch(CommonActionTypes.SetStatus, status);

        const result: Array<Materialartikel> = res.data
        commit(MutationTypes.GetMaterialartikelFlaschenSuccss, result)
      })
      .catch(error => {
        status.materialartikelFlaschenLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.materialartikelFlaschenErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.materialartikelFlaschenErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.materialartikelFlaschenErrorMsg = error
        }
        
        dispatch(CommonActionTypes.SetStatus, status);
      })
  }
}
