export class Status {
  errorMsg: string;
  
  bestandskontrolleLoading: boolean;
  bestandskontrolleErrorMsg: string;

  archivLoggedIn: boolean;
  archivErrorMsg: string
  archivLoading: boolean;

  lagerLoading: boolean;
  lagerErrorMsg: string;

  lagerartikelLoading: boolean;
  lagerartikelErrorMsg: string;

  lagerplatzartenLoading: boolean;
  lagerplatzartenErrorMsg: string;

  materialartikelLoading: boolean;
  materialartikelErrorMsg: string;
  
  materiallieferantartikelLoading: boolean;
  materiallieferantartikelErrorMsg: string;
  
  lieferantartikelLoading: boolean;
  lieferantartikelErrorMsg: string;
  
  lieferantLoading: boolean;
  lieferantErrorMsg: string;
  
  lieferherstartikelLoading: boolean;
  lieferherstartikelErrorMsg: string;
  
  herstellerartikelLoading: boolean;
  herstellerartikelErrorMsg: string;
  
  herstellerLoading: boolean;
  herstellerErrorMsg: string;
  
  loginlagerdbLoading: boolean;
  loginlagerdbErrorMsg: string;
  
  mitarbeiterLoading: boolean;
  mitarbeiterErrorMsg: string;
  
  planungLoading: boolean;
  planungErrorMsg: string;

  printdataLoading: boolean;
  printdataErrorMsg: string;

  printdataFileListLoading: boolean;
  printdataFileListErrorMsg: string;

  maschinenLoading: boolean;
  maschinenErrorMsg: string;

  personalLoading: boolean;
  personalErrorMsg: string;

  flaschenBestandLoading: boolean;
  flaschenBestandErrorMsg: string;
  
  kartonsBestandLoading: boolean;
  kartonsBestandErrorMsg: string;
  
  kapselnBestandLoading: boolean;
  kapselnBestandErrorMsg: string;
  
  materialartikelFlaschenLoading: boolean;
  materialartikelFlaschenErrorMsg: string;

  materialartikelKartonsLoading: boolean;
  materialartikelKartonsErrorMsg: string;

  materialartikelKapselnLoading: boolean;
  materialartikelKapselnErrorMsg: string;

  produktionsstatusLoading: boolean;
  produktionsstatusFetched: boolean;
  produktionsstatusErrorMsg: string;

  maschinenstatusLoading: boolean;
  maschinenstatusErrorMsg: string;

  commonLoading: boolean;

  sidebarCollapsed: boolean;


  constructor () {
    this.errorMsg = "";

    this.bestandskontrolleLoading = false;
    this.bestandskontrolleErrorMsg = "";

    this.archivLoggedIn = false;
    this.archivErrorMsg = "";
    this.archivLoading = false;

    this.lagerLoading = false;
    this.lagerErrorMsg = "";

    this.lagerartikelLoading = false;
    this.lagerartikelErrorMsg = "";

    this.lagerplatzartenLoading = false;
    this.lagerplatzartenErrorMsg = "";

    this.materialartikelLoading = false;
    this.materialartikelErrorMsg = "";
    
    this.materiallieferantartikelLoading = false;
    this.materiallieferantartikelErrorMsg = "";
    
    this.lieferantartikelLoading = false;
    this.lieferantartikelErrorMsg = "";
    
    this.lieferantLoading = false;
    this.lieferantErrorMsg = "";
    
    this.lieferherstartikelLoading = false;
    this.lieferherstartikelErrorMsg = "";
    
    this.herstellerartikelLoading = false;
    this.herstellerartikelErrorMsg = "";
    
    this.herstellerLoading = false;
    this.herstellerErrorMsg = "";
    
    this.loginlagerdbLoading = false;
    this.loginlagerdbErrorMsg = "";
    
    this.mitarbeiterLoading = false;
    this.mitarbeiterErrorMsg = "";
    
    this.planungLoading = false;
    this.planungErrorMsg = "";
    
    this.printdataLoading = false;
    this.printdataErrorMsg = "";
    
    this.printdataFileListLoading = false;
    this.printdataFileListErrorMsg = "";
    
    this.maschinenLoading = false;
    this.maschinenErrorMsg = "";
    
    this.personalLoading = false;
    this.personalErrorMsg = "";
    
    this.flaschenBestandLoading = false;
    this.flaschenBestandErrorMsg = "";
    
    this.kartonsBestandLoading = false;
    this.kartonsBestandErrorMsg = "";
    
    this.kapselnBestandLoading = false;
    this.kapselnBestandErrorMsg = "";
    
    this.materialartikelFlaschenLoading = false;
    this.materialartikelFlaschenErrorMsg = "";

    this.materialartikelKartonsLoading = false;
    this.materialartikelKartonsErrorMsg = "";

    this.materialartikelKapselnLoading = false;
    this.materialartikelKapselnErrorMsg = "";

    this.produktionsstatusLoading = false;
    this.produktionsstatusFetched = false;
    this.produktionsstatusErrorMsg = "";

    this.maschinenstatusLoading = false;
    this.maschinenstatusErrorMsg = "";

    this.commonLoading = false;

    this.sidebarCollapsed = false;
  }
}


export class Email {
  fromAdresse: string
  fromName: string
  toAdresse: string
  toName: string
  subject: string
  content: string

  constructor( fromAdresse: string, fromName: string, toAdresse: string, toName: string, subject: string, content: string) {
    this.fromAdresse = fromAdresse
    this.fromName = fromName
    this.toAdresse = toAdresse
    this.toName = toName
    this.subject = subject
    this.content = content
  }
}

export interface ErrorResponseData {
  detail: string
  status: number
  title: string
  type: string
}