<template>
  <div v-if="planung != null && planung.listePlanungResource != null && planung.listePlanungResource.length > 0" class="d-flex flex-column p-1">
    <div v-for="plan in planung.listePlanungResource" class="card border mb-1 rounded-0 small" :class="getProdAuftragBorderColor(plan.gesamtanzahl, plan.gesamtanzahlIst)">
      <!-- <div class="card-header rounded-0 p-1" :class="getProdAuftragBgColor(plan.gesamtanzahl, plan.gesamtanzahlIst)"><p class="p-0 m-0 text-truncate">{{ plan.artikelnr }}: {{  getArtikelbezeichnungKurz(plan.artikel) }}</p></div> -->
      <div class="card-header rounded-0 p-1" :class="getProdAuftragBgColor(plan.gesamtanzahl, plan.gesamtanzahlIst)"><p class="p-0 m-0 text-truncate">{{ plan.artikel }}</p></div>
      <div class="card-body p-1">
        <div class="d-flex justify-content-start mb-1">
          <div class="pe-1 border-end">
            {{ plan.artikelnr }}
          </div>
          <div class="px-1 border-end">
            {{ plan.landnr }} {{ plan.landkz }}
          </div>
          <div class="px-1 flex-fill border-end">
            {{ plan.kommissionid }}
          </div>
          <div class="ps-2" :class="getProdAuftragTextColor(plan.gesamtanzahl, plan.gesamtanzahlIst)">
            {{ plan.gesamtanzahlIst }}/{{ plan.gesamtanzahl }}
          </div>
          <!-- <div v-if="plan.text != ''" class="pt-1 border-top">{{ plan.text }}</div> -->

        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import { PlanungMaschinenHeute } from "@/models/PlanungModels";

export default defineComponent({
  name: 'ProduktionPlanung',
  components: {
    LoadingSpinner
  },

  props: {
    planung: {
      type: Object as PropType<PlanungMaschinenHeute>,
      default: null
    },
  },

  methods: {
    getArtikelbezeichnungKurz(artikel: string) {
      let artikelname = artikel.split(": ")[1]
      return artikelname
    },

    getProdAuftragBgColor(anzahlSoll: number, anzahlIst: number) {
      if (anzahlSoll == anzahlIst) {
        return "bg-success bg-opacity-25"
      }
      else if (anzahlIst > 0 && anzahlSoll > anzahlIst) {
        return "bg-warning bg-opacity-50"
      }
      else {
        return "bg-secondary bg-opacity-50"
      }
    },

    getProdAuftragBorderColor(anzahlSoll: number, anzahlIst: number) {
      if (anzahlSoll == anzahlIst) {
        return "border-success"
      }
      else if (anzahlIst > 0 && anzahlSoll > anzahlIst) {
        return "border-warning"
      }
      else {
        return "border-secondary"
      }
    },

    getProdAuftragTextColor(anzahlSoll: number, anzahlIst: number) {
      if (anzahlSoll == anzahlIst) {
        return "text-success fw-bold"
      }
      else if (anzahlIst > 0 && anzahlSoll > anzahlIst) {
        return "text-warning fw-bold"
      }
      else {
        return ""
      }
    }
  },

})
</script>

<style lang="scss" scoped>
</style>