import axios, { AxiosResponse } from "axios";
import {setupInterceptorsTo} from './ApiInterceptors';
import { Email } from "@/models/CommonModels";

/* DEV */
// import config from '../../appsettings.dev.json'

/* PROD */
const appsettings = await fetch('/appsettings.json')
const config = await appsettings.json() 

const BASE_URL = config.erpapi.apiUrl;
const API_KEY = config.erpapi.apiKey;

const apiClient = setupInterceptorsTo(axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'ApiKey': API_KEY
  }
}));

export default {
  sendEmail(email: Email): Promise<AxiosResponse> {
    return apiClient.post(`/email/senden`, email)
  },
}