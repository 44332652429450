import axios, { AxiosResponse } from "axios";
import {setupInterceptorsTo} from './PpsApiInterceptors';

/* DEV */
// import config from '../../appsettings.dev.json'

/* PROD */
const appsettings = await fetch('/appsettings.json')
const config = await appsettings.json() 

const BASE_URL = config.erpapi.apiUrl;
const API_KEY = config.erpapi.apiKey;

const apiClient = setupInterceptorsTo(axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'ApiKey': API_KEY
  }
}));

export default {
  // retryRequest(config: AxiosRequestConfig) {
  //   return apiClient.request(config);
  // },
  // Produktion
  getMaschinen(): Promise<AxiosResponse> {
    return apiClient.get('pps/produktion/maschinen')
  },
  getProduktionsstatus(): Promise<AxiosResponse> {
    return apiClient.get('pps/produktion/status')
  },
  getProduktionspuffer(): Promise<AxiosResponse> {
    return apiClient.get('pps/produktion/lagerpuffer')
  },

  // Mitarbeiter
  getMitarbeiterPersonalstatus(): Promise<AxiosResponse> {
    return apiClient.get('pps/mitarbeiter/kostenstelle')
  },

  // Material
  getAlleFlaschenBestand(): Promise<AxiosResponse> {
    return apiClient.get('pps/material/flaschen/bestand')
  },
  getAlleKartonsBestand(): Promise<AxiosResponse> {
    return apiClient.get('pps/material/kartons/bestand')
  },
  getAlleKapselnBestand(): Promise<AxiosResponse> {
    return apiClient.get('pps/material/kapseln/bestand')
  }
}