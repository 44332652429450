import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-69a4620c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "statusAnzeige" }
const _hoisted_2 = {
  key: 0,
  class: "alert alert-danger"
}
const _hoisted_3 = {
  key: 2,
  class: "container-fluid"
}
const _hoisted_4 = { class: "row" }
const _hoisted_5 = {
  key: 0,
  class: "col-2 mb-5 bg-white shadow border p-0"
}
const _hoisted_6 = { class: "text-muted small" }
const _hoisted_7 = {
  key: 1,
  class: "col-2 mb-5 bg-white shadow border p-0"
}
const _hoisted_8 = { class: "text-muted small" }
const _hoisted_9 = {
  key: 2,
  class: "col-2 mb-5 bg-white shadow border p-0"
}
const _hoisted_10 = { class: "text-muted small" }
const _hoisted_11 = {
  key: 3,
  class: "col-2 mb-5 bg-white shadow border p-0"
}
const _hoisted_12 = { class: "text-muted small" }
const _hoisted_13 = {
  key: 4,
  class: "col-2 mb-5 bg-white shadow border p-0"
}
const _hoisted_14 = { class: "text-muted small" }
const _hoisted_15 = {
  key: 5,
  class: "col-2 mb-5 bg-white shadow border p-0"
}
const _hoisted_16 = { class: "text-muted small" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LoadingSpinner = _resolveComponent("LoadingSpinner")!
  const _component_MaschinePlanung = _resolveComponent("MaschinePlanung")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, " Aktualisiert um: " + _toDisplayString(_ctx.updateDatetimeConverter), 1),
    (_ctx.errorMsg)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.errorMsg), 1))
      : _createCommentVNode("", true),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_LoadingSpinner, {
          key: 1,
          text: '...Daten werden geladen...'
        }, null, 8, ["text"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.maschinenPlanungHeuteM1 != null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["p-2 mb-1 d-flex justify-content-between", _ctx.getHeaderBg(_ctx.maschinenPlanungHeuteM1.maschinenzustand)])
                  }, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.maschinenPlanungHeuteM1.maschinename), 1),
                    _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.maschinenPlanungHeuteM1.maschinenzustand), 1)
                  ], 2),
                  _createVNode(_component_MaschinePlanung, {
                    planung: _ctx.maschinenPlanungHeuteM1.planung
                  }, null, 8, ["planung"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.maschinenPlanungHeuteM10 != null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["p-2 mb-1 d-flex justify-content-between", _ctx.getHeaderBg(_ctx.maschinenPlanungHeuteM10.maschinenzustand)])
                  }, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.maschinenPlanungHeuteM10.maschinename), 1),
                    _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.maschinenPlanungHeuteM10.maschinenzustand), 1)
                  ], 2),
                  _createVNode(_component_MaschinePlanung, {
                    planung: _ctx.maschinenPlanungHeuteM10.planung
                  }, null, 8, ["planung"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.maschinenPlanungHeuteM12 != null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["p-2 mb-1 d-flex justify-content-between", _ctx.getHeaderBg(_ctx.maschinenPlanungHeuteM12.maschinenzustand)])
                  }, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.maschinenPlanungHeuteM12.maschinename), 1),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.maschinenPlanungHeuteM12.maschinenzustand), 1)
                  ], 2),
                  _createVNode(_component_MaschinePlanung, {
                    planung: _ctx.maschinenPlanungHeuteM12.planung
                  }, null, 8, ["planung"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.maschinenPlanungHeuteM6 != null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["p-2 mb-1 d-flex justify-content-between", _ctx.getHeaderBg(_ctx.maschinenPlanungHeuteM6.maschinenzustand)])
                  }, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.maschinenPlanungHeuteM6.maschinename), 1),
                    _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.maschinenPlanungHeuteM6.maschinenzustand), 1)
                  ], 2),
                  _createVNode(_component_MaschinePlanung, {
                    planung: _ctx.maschinenPlanungHeuteM6.planung
                  }, null, 8, ["planung"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.maschinenPlanungHeuteM8 != null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["p-2 mb-1 d-flex justify-content-between", _ctx.getHeaderBg(_ctx.maschinenPlanungHeuteM8.maschinenzustand)])
                  }, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.maschinenPlanungHeuteM8.maschinename), 1),
                    _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.maschinenPlanungHeuteM8.maschinenzustand), 1)
                  ], 2),
                  _createVNode(_component_MaschinePlanung, {
                    planung: _ctx.maschinenPlanungHeuteM8.planung
                  }, null, 8, ["planung"])
                ]))
              : _createCommentVNode("", true),
            (_ctx.maschinenPlanungHeuteM24 != null)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["p-2 mb-1 d-flex justify-content-between", _ctx.getHeaderBg(_ctx.maschinenPlanungHeuteM24.maschinenzustand)])
                  }, [
                    _createElementVNode("strong", null, _toDisplayString(_ctx.maschinenPlanungHeuteM24.maschinename), 1),
                    _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.maschinenPlanungHeuteM24.maschinenzustand), 1)
                  ], 2),
                  _createVNode(_component_MaschinePlanung, {
                    planung: _ctx.maschinenPlanungHeuteM24.planung
                  }, null, 8, ["planung"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]))
  ], 64))
}