<template>
  <!-- <nav id="sidebar" class="d-flex flex-column flex-shrink-0 p-3 bg-primary text-light shadow text-start"> -->
  <nav id="sidebar" class="bg-primary text-light shadow d-flex flex-column flex-shrink-0" :class="sidebarCollapsed ? 'sidebar-collapsed' : 'sidebar-notcollapsed p-3'">

    <!-- <img v-if="sidebarCollapsed" src="@/assets/icons/ew.png" class="mx-auto mt-3" width="30" @click="toggleSidebar()"> -->
    
    <router-link
      v-if="!sidebarCollapsed"
      to="/"
      class="
        d-flex
        align-items-center
        mb-3 mb-md-0
        me-md-auto
        text-decoration-none
        text-light
      "
    >
      <!-- <font-awesome-icon icon="users-line" class="me-3 fa-2x" /> -->
      <img src="@/assets/icons/ew.png" class="me-3" width="40">
      <span class="fs-4">Dashboard</span>
    </router-link>

    <hr v-if="!sidebarCollapsed">

    <ul v-if="!sidebarCollapsed" class="nav nav-pills flex-column mb-auto">
      <!-- Produktion -->
      <li class="nav-item mb-3">
        <button
          class="btn btn-toggle w-100 d-flex text-white px-1 py-2"
          data-bs-toggle="collapse"
          data-bs-target="#produktion-collapse"
          aria-expanded="true"
        >
          <!-- <font-awesome-icon icon="clipboard" class="me-3 fa-lg" style="width: 30px;"/><span class="flex-fill text-start">Produktion</span> -->
          <div class="me-2 fa-lg d-flex align-items-start" style="width: 30px;"><font-awesome-icon icon="clipboard" /></div><span class="flex-fill text-start">Produktion</span>
        </button>
        <div class="collapse show" id="produktion-collapse">
          <ul class="btn-toggle-nav nav nav-pills flex-column mb-auto small">
            <li class="nav-item">
              <router-link to="/produktion/maschinen" class="nav-link text-white d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Produktionsplan
              </router-link>
            </li>

            <li class="nav-item">
              <router-link to="/produktion" class="nav-link text-white d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Maschinen
              </router-link>
            </li>
          </ul>
        </div>
      </li>

      <!-- Herstellung -->
      <!-- <li class="nav-item mb-3" v-if="lagerUser.lagerUser.herstellung == 1">
        <button
          class="btn btn-toggle w-100 d-flex justify-content-between text-white"
          data-bs-toggle="collapse"
          data-bs-target="#herstellung-collapse"
          aria-expanded="true"
        >
          <span><font-awesome-icon icon="flask" class="me-3 fa-lg" />Herstellung</span>
        </button>
        <div class="collapse show" id="herstellung-collapse">
          <ul class="btn-toggle-nav nav nav-pills flex-column ms-4 mb-auto small">
            <li class="nav-item">
              <router-link to="/herstellung" class="nav-link text-white" active-class="active">
                <span><font-awesome-icon icon="flask" class="me-3 fa-lg" />Herstellung</span>
              </router-link>
            </li>
          </ul>
        </div>
      </li> -->
      <li class="nav-item mb-3" v-if="lagerUser.lagerUser.herstellung == 1">
        <router-link to="/herstellung" class="nav-link text-white w-100 d-flex px-1 rounded-0" active-class="active">
          <!-- <font-awesome-icon icon="flask" class="me-3 fa-lg" style="width: 30px;"/><span class="flex-fill text-start">Herstellung</span> -->
          <div class="me-2 fa-lg d-flex align-items-start" style="width: 30px;"><font-awesome-icon icon="flask" /></div><span class="flex-fill text-start">Herstellung</span>
        </router-link>
      </li>
      
      <!-- Verwaltung -->
      <!-- <li class="nav-item mb-3" v-if="lagerUser.lagerUser.verwaltung == 1">
        <button
          class="btn btn-toggle w-100 d-flex justify-content-between text-white"
          data-bs-toggle="collapse"
          data-bs-target="#verwaltung-collapse"
          aria-expanded="true"
        >
          <span><font-awesome-icon icon="folder" class="me-3 fa-lg" />Verwaltung</span>
        </button>
        <div class="collapse show" id="verwaltung-collapse">
          <ul class="btn-toggle-nav nav nav-pills flex-column ms-4 mb-auto small">
            <li class="nav-item">
              <router-link to="/verwaltung" class="nav-link text-white" active-class="active">
                <span><font-awesome-icon icon="folder" class="me-3 fa-lg" />Verwaltung</span>
              </router-link>
            </li>
          </ul>
        </div>
      </li> -->

      <!-- Lager -->
      <li class="nav-item mb-3">
        <button
          class="btn btn-toggle w-100 d-flex text-white px-1 py-2"
          data-bs-toggle="collapse"
          data-bs-target="#lager-collapse"
          aria-expanded="true"
        >
          <!-- <div style="width: 25px;" class="me-3"><font-awesome-icon icon="boxes-stacked" class="fa-lg" /></div>Lager -->
          <!-- <font-awesome-icon icon="boxes-stacked" class="me-3 fa-lg" style="width: 30px;"/><span class="flex-fill text-start">Lager</span> -->
          <div class="me-2 fa-lg d-flex align-items-start" style="width: 30px;"><font-awesome-icon icon="boxes-stacked" /></div><span class="flex-fill text-start">Lager</span>
        </button>
        <div class="collapse show" id="lager-collapse">
          <ul class="btn-toggle-nav nav nav-pills flex-column mb-auto small">
            <li class="nav-item">
              <router-link to="/lager/fertigwaren" class="nav-link text-white d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Fertigwaren Lager
              </router-link>
            </li>

            <li class="nav-item">
              <router-link to="/lager/material" class="nav-link text-white d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Material Lager
              </router-link>
            </li>

            <li class="nav-item">
              <router-link to="/lager/produktion" class="nav-link text-white d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Produktion Lager
              </router-link>
            </li>

            <li class="nav-item">
              <router-link to="/lager/herstellung" class="nav-link text-white d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Herstellung Lager
              </router-link>
            </li>
          </ul>
        </div>
      </li>

        <!-- Mitarbeiter -->
        <!-- <li class="nav-item mb-3" v-if="lagerUser.lagerUser.verwaltung == 1">
        <button
          class="btn btn-toggle w-100 d-flex justify-content-between text-white"
          data-bs-toggle="collapse"
          data-bs-target="#mitarbeiter-collapse"
          aria-expanded="true"
        >
          <span><font-awesome-icon icon="users" class="me-3 fa-lg" />Mitarbeiter</span>
        </button>
        <div class="collapse show" id="mitarbeiter-collapse">
          <ul class="btn-toggle-nav nav nav-pills flex-column ms-4 mb-auto small">
            <li class="nav-item">
              <router-link to="/mitarbeiter" class="nav-link text-white" active-class="active">
                <span><font-awesome-icon icon="users" class="me-3 fa-lg" />Mitarbeiter</span>
              </router-link>
            </li>
          </ul>
        </div>
      </li> -->
      <li class="nav-item mb-3" v-if="lagerUser.lagerUser.verwaltung == 1">
        <router-link to="/mitarbeiter" class="nav-link d-flex w-100 text-white px-1 rounded-0" active-class="active">
          <!-- <span style="width: 25px;"><font-awesome-icon icon="users" class="me-3 fa-lg" /></span>Mitarbeiter -->
          <!-- <font-awesome-icon icon="users" class="me-3 fa-lg" style="width: 30px;"/><span class="flex-fill text-start">Mitarbeiter</span> -->
          <div class="me-2 fa-lg d-flex align-items-start" style="width: 30px;"><font-awesome-icon icon="users" /></div><span class="flex-fill text-start">Mitarbeiter</span>
        </router-link>
      </li>

        <!-- Material -->
      <li class="nav-item mb-3" v-if="lagerUser.lagerUser.materialbestellung == 1">
        <button
          class="btn btn-toggle w-100 d-flex text-white px-1 py-2"
          data-bs-toggle="collapse"
          data-bs-target="#material-collapse"
          aria-expanded="true"
        >
          <!-- <span style="width: 25px;"><font-awesome-icon icon="angle-double-down" class="me-3 fa-lg" /></span>Material -->
          <div class="me-2 fa-lg d-flex align-items-start" style="width: 30px;"><font-awesome-icon icon="angle-double-down" /></div><span class="flex-fill text-start">Material</span>

        </button>
        <div class="collapse show" id="material-collapse">
          <ul class="btn-toggle-nav nav nav-pills flex-column mb-auto small">
            <li class="nav-item">
              <router-link to="/material/flaschen" class="nav-link text-white d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Flaschen Bestand
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/material/kartons" class="nav-link text-white d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Karton Bestand
              </router-link>
            </li>
            <li class="nav-item">
              <router-link to="/material/kapseln" class="nav-link text-white d-flex px-1 rounded-0" active-class="active">
                <div class="me-2" style="width: 30px;"></div>Kapsel Bestand
              </router-link>
            </li>
          </ul>
        </div>
      </li>

    </ul>

    <hr v-if="!sidebarCollapsed">
  </nav>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useStore } from '@/store'

export default defineComponent({

  setup () {
    const store = useStore();

    const user = computed(() => store.getters.authUser)
    const lagerUser = computed(() => store.getters.lagerUser)

    const sidebarCollapsed = computed(() => store.getters.status.sidebarCollapsed);

    return {
      store,
      user,
      lagerUser,
      sidebarCollapsed
    }
  },

});
</script>

<style lang="scss" scoped>
.sidebar-notcollapsed {
  width: 250px;
  transition: width .3s ease-in-out;
}
.sidebar-collapsed {
  width: 15px;
  transition: width .3s ease-in-out;
}

.active {
  background: #fff !important;
  color: #2A579B !important;
  // color: #fff !important;
}

.btn-toggle[aria-expanded="true"]::after {
    transform: rotate(0deg);
}

.btn-toggle::after {
    width: 1.25em;
    margin: auto 0 auto 0;
    line-height: 0;
    transition: transform .35s ease;
    transform: rotate(-90deg);
    content: url(../assets/icons/chevron-down-white.svg)
  }
</style>
  <!-- content: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e); -->