import { ActionContext, ActionTree } from "vuex";
import LagerService from '@/service/LagerService'
import ApiService from '@/service/ApiService'

import { RootState } from '@/store';

import { State } from "./state"

import { Mutations, MutationTypes } from "./mutations";

import { Actions as CommonActions, ActionTypes as CommonActionTypes } from "../../Common/actions";

import { Printdata } from "@/models/PrintdataModels"
import { Email } from "@/models/CommonModels";




export enum ActionTypes {
  GetPrintdataFileList = "GETPRINDATAFILELIST",
  DownloadPrintdataFile = "DOWNLOADPRINDDATAFILE",
  UploadPrintdataFile = "UPLOADPRINDDATAFILE",
  DeletePrintdataFile = "DELETEPRINDDATAFILE",
  SendPrintdataEmail = "SENDPRINTDATAEMAIL",
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetPrintdataFileList](context: ActionArguments): Promise<void>,
  [ActionTypes.DownloadPrintdataFile](context: ActionArguments, fileName: string): Promise<void>,
  [ActionTypes.UploadPrintdataFile](context: ActionArguments, file: FormData): Promise<void>,
  [ActionTypes.DeletePrintdataFile](context: ActionArguments, fileName: string): Promise<void>,
  [ActionTypes.SendPrintdataEmail](context: ActionArguments, email: Email): Promise<void>,
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetPrintdataFileList]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.printdataFileListLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return LagerService.getPrintdate()
      .then(res => {
        status.printdataFileListLoading = false
        dispatch(CommonActionTypes.SetStatus, status);

        const result: Array<Printdata> = res.data
        commit(MutationTypes.GetPrintdataFilelistSuccess, result)
      })
      .catch(error => {
        status.printdataFileListLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.printdataFileListErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.printdataFileListErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.printdataFileListErrorMsg = error
        }
        
        sessionStorage.removeItem("printdata");
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DownloadPrintdataFile]({ dispatch, rootGetters }, fileName) {
    const status = rootGetters.status;
    status.printdataLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return LagerService.downloadPrintdate(fileName)
      .then(res => {
        status.printdataLoading = false
        dispatch(CommonActionTypes.SetStatus, status);

        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
      })
      .catch(error => {
        status.printdataLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.printdataErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.printdataErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.printdataErrorMsg = error
        }

        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
  
  async [ActionTypes.UploadPrintdataFile]({ dispatch, rootGetters }, file) {
    const status = rootGetters.status;
    status.printdataLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return LagerService.uploadPrintdate(file)
      .then(() => {
        status.printdataLoading = false
        dispatch(CommonActionTypes.SetStatus, status);

        dispatch(ActionTypes.GetPrintdataFileList)
      })
      .catch(error => {
        status.printdataLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.printdataErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.printdataErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.printdataErrorMsg = error
        }

        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.DeletePrintdataFile]({ commit, dispatch, rootGetters }, fileName) {
    const status = rootGetters.status;
    status.printdataLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return LagerService.deletePrintdate(fileName)
      .then(() => {
        commit(MutationTypes.DeletePrintdataFileSuccess, fileName);
        status.printdataLoading = false
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .catch(error => {
        status.printdataLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.printdataErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.printdataErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.printdataErrorMsg = error
        }

        dispatch(CommonActionTypes.SetStatus, status);
      })
    
  },

  async [ActionTypes.SendPrintdataEmail]({ commit, dispatch, rootGetters }, email) {
    const status = rootGetters.status;
    status.printdataLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return ApiService.sendEmail(email)
      .then(() => {
        status.printdataLoading = false
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .catch(error => {
        status.printdataLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.printdataErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.printdataErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.printdataErrorMsg = error
        }

        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
}
