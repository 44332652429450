import { createApp } from 'vue'

import App from './App.vue'
import router from "./router"
import { store } from './store'
// import './registerServiceWorker'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faWindows } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import {
  faBars,
  faSync,
  faDownload,
  faShareSquare,
  faTrash,
  faCopy,
  faPaperPlane,
  faPlus,
  faUsers,
  faChevronRight,
  faList,
  faAddressCard,
  faObjectGroup,
  faLayerGroup,
  faBoxOpen,
  faWineBottle,
  faAngleDoubleDown,
  faListOl,
  faSearchPlus,
  faEnvelope,
  faInfoCircle,
  faInfo,
  faChevronLeft,
  faArrowRight,
  faArrowRightToBracket,
  faArrowRightFromBracket,
  faClipboard,
  faFlask,
  faFolder,
  faBoxesStacked,
  faPrint
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faArrowRight,
  faArrowRightToBracket,
  faArrowRightFromBracket,
  faBars,
  faSync,
  faDownload,
  faShareSquare,
  faTrash,
  faCopy,
  faPaperPlane,
  faPlus,
  faUsers,
  faChevronRight,
  faChevronLeft,
  faList,
  faListOl,
  faAddressCard,
  faObjectGroup,
  faLayerGroup,
  faBoxOpen,
  faWineBottle,
  faAngleDoubleDown,
  faSearchPlus,
  faEnvelope,
  faInfoCircle,
  faInfo,
  faClipboard,
  faFlask,
  faFolder,
  faBoxesStacked,
  faPrint,
  faWindows
);

const app = createApp(App);
app.use(store)
app.use(router)
app.component("font-awesome-icon", FontAwesomeIcon)
app.mount('#app')

import "bootstrap"