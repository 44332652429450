import axios, { AxiosResponse } from "axios";
import {setupInterceptorsTo} from './LagerApiInterceptors';
import { LagerLogoutRequest, RefreshLoginRequest } from "@/models/MitarbeiterModels";

/* DEV */
// import config from '../../appsettings.dev.json'

/* PROD */
const appsettings = await fetch('/appsettings.json')
const config = await appsettings.json() 

const BASE_URL = config.erpapi.apiUrl;
const API_KEY = config.erpapi.apiKey;

const apiClient = setupInterceptorsTo(axios.create({
  baseURL: BASE_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'ApiKey': API_KEY
  }
}));

export default {
  // retryRequest(config: AxiosRequestConfig) {
  //   return apiClient.request(config);
  // },
  // MITARBEITER
  loginLager(): Promise<AxiosResponse> {
    return apiClient.get('/lager/mitarbeiter/login')
  },
  logoutLager(data: LagerLogoutRequest): Promise<AxiosResponse> {
    return apiClient.post('/lager/mitarbeiter/logout', data)
  },
  refreshLoginLager(data: RefreshLoginRequest): Promise<AxiosResponse> {
    return apiClient.post('/lager/mitarbeiter/refresh', data)
  },
  getMitarbeiter() {
    return apiClient.get('/lager/mitarbeiter/list')
  },

  // HERSTELLUNG
  getBestandskontrolle(): Promise<AxiosResponse> {
    return apiClient.get('/lager/herstellung/bestandskontrolle')
  },
  getBestandskontrolleRohstoffGruppiertTage(tage: number): Promise<AxiosResponse> {
    return apiClient.get(`/lager/herstellung/bestandskontrolle/${tage}`)
  },

  // PRODUKTION
  getLagerMaschinen(): Promise<AxiosResponse> {
    return apiClient.get('/lager/produktion/maschinen')
  },
  
  getMaschinenPlanungHeute(maschinenname: string): Promise<AxiosResponse> {
    return apiClient.get(`/lager/produktion/planung/${maschinenname}`)
  },

  getMaschinenPlanungHeuteListeartikel(maschinenname: string): Promise<AxiosResponse> {
    return apiClient.get(`/lager/produktion/planung/listeartikel/${maschinenname}`)
  },
  

  // LAGER
  getLagerArtikelBestand(): Promise<AxiosResponse> {
    return apiClient.get('/lager/artikel/bestand/dashboard')
  },
  getLagerplaetze(): Promise<AxiosResponse> {
    return apiClient.get('/lager/lagerplaetze')
  },
  getLagerplatzarten (): Promise<AxiosResponse> {
    return apiClient.get('/lager/lagerplatzarten')
  },
  getLagerpuffer(): Promise<AxiosResponse> {
    return apiClient.get('/lager/lagerpuffer')
  },
  getEinlagernEinzelnPPS(id: number): Promise<AxiosResponse> {
    return apiClient.get(`/lager/einlagern/einzeln/pps/${id}`)
  },
  getEinlagernEinzelnListeartikel(id: number): Promise<AxiosResponse> {
    return apiClient.get(`/lager/einlagern/einzeln/listeartikel/${id}`)
  },

  // LAGER MATERIAL
  getMaterialartikel(): Promise<AxiosResponse> {
    return apiClient.get(`/lager/material/artikel`)
  },
  getAlleMaterialartikelFlaschen(): Promise<AxiosResponse> {
    return apiClient.get('/lager/material/artikel/flaschen')
  },
  getMateriallieferantartikel(): Promise<AxiosResponse> {
    return apiClient.get(`/lager/material/material/lieferant/artikel`)
  },
  getLieferantartikel(): Promise<AxiosResponse> {
    return apiClient.get(`/lager/material/lieferant/artikel`)
  },
  getLieferanten(): Promise<AxiosResponse> {
    return apiClient.get(`/lager/material/lieferanten`)
  },
  getLieferherstartikel(): Promise<AxiosResponse> {
    return apiClient.get(`/lager/material/lieferant/hersteller/artikel`)
  },
  getHerstellerartikel(): Promise<AxiosResponse> {
    return apiClient.get(`/lager/material/hersteller/artikel`)
  },
  getHersteller(): Promise<AxiosResponse> {
    return apiClient.get(`/lager/material/hersteller`)
  },  

  // VERWALTUNG/PRINTDATA
  getPrintdate(): Promise<AxiosResponse> {
    return apiClient.get(`/lager/verwaltung/printdata/files`)
  },
  downloadPrintdate(fileName: string): Promise<AxiosResponse> {
    return apiClient.get(`/lager/verwaltung/printdata/download/${fileName}`, { responseType: 'blob' })
  },
  uploadPrintdate(file: FormData): Promise<AxiosResponse> {
    return apiClient.post(
      `/lager/verwaltung/printdata/upload`,
      file,
      {
        onUploadProgress: (progressEvent) => {
          var progressBar = document.getElementById('uploadProgress')
          if (progressBar != null && progressBar.firstChild != null) {
            const child = progressBar.firstChild as HTMLElement
            child.style.width = Math.round((progressEvent.loaded * 100) / progressEvent.total) + '%'
          }
        },
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    )
  },
  deletePrintdate(fileName: string): Promise<AxiosResponse> {
    return apiClient.delete(`/lager/verwaltung/printdata/delete/${fileName}`)
  }
}