import { ActionContext, ActionTree } from "vuex";
import LagerService from '@/service/LagerService'
import PpsService from "@/service/PpsService";

import { RootState } from '@/store';

import { State } from "./state"

import { Mutations, MutationTypes } from "./mutations";

import { ActionTypes as CommonActionTypes } from "../../Common/actions";
import { Listeartikel, LagerplatzResponse, Lagerplatzart } from "@/models/LagerModels";
import { Listeppsid } from "@/models/PpsModels"



export enum ActionTypes {
  GetArtikelImLager = "GETARTIKELIMLAGER",
  UpdateGetArtikelImLager = "UPDATEGETARTIKELIMLAGER",
  GetLagerzustand = "GETLAGERZUSTAND",
  GetLagerpufferDaten = "GETLAGERPUFFER",
  GetProduktionspufferDaten = "GETPRODUKTIONSPUFFER",
  UpdateGetLagerzustand = "UPDATEGETLAGERZUSTAND",
  GetLagerplatzartenDaten = "GETLAGERPLATZARTEN"
}

type ActionArguments = Omit<ActionContext<State, RootState>, "commit"> & {
  commit<K extends keyof Mutations>(
    key: K,
    payload: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>
}

export type Actions = {
  [ActionTypes.GetArtikelImLager](context: ActionArguments): Promise<void>,
  [ActionTypes.UpdateGetArtikelImLager](context: ActionArguments): Promise<void>,
  [ActionTypes.GetLagerzustand](context: ActionArguments): Promise<void>,
  [ActionTypes.GetLagerpufferDaten](context: ActionArguments): Promise<void>,
  [ActionTypes.GetProduktionspufferDaten](context: ActionArguments): Promise<void>,
  [ActionTypes.UpdateGetLagerzustand](context: ActionArguments): Promise<void>,
  [ActionTypes.GetLagerplatzartenDaten](context: ActionArguments): Promise<void>,
}

export const actions: ActionTree<State, RootState> & Actions = {
  async [ActionTypes.GetArtikelImLager]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.lagerartikelLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return LagerService.getLagerArtikelBestand()
      .then(res => {
        status.lagerartikelLoading = false
        dispatch(CommonActionTypes.SetStatus, status);

        const result: Array<Listeartikel> = res.data
        commit(MutationTypes.GetArtikelImLagerSuccess, result)
      })
      .catch(error => {
        status.lagerartikelLoading = false
        // console.log(error);
        if (error.message === 'Network Error') {
          status.lagerartikelErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.lagerartikelErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.lagerartikelErrorMsg = error
        }
        
        sessionStorage.removeItem("bestandskontrolle");
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateGetArtikelImLager]({ commit, dispatch, rootGetters }) {
    
    return LagerService.getLagerArtikelBestand()
    .then(res => {
      const result: Array<Listeartikel> = res.data
      commit(MutationTypes.GetArtikelImLagerSuccess, result)
    })
    .catch(error => {
        const status = rootGetters.status;
        // console.log(error);
        if (error.message === 'Network Error') {
          status.lagerartikelErrorMsg = error.message
        } else if (error.response?.status == 401){
          status.lagerartikelErrorMsg = "Sie haben keinen Zugriff auf diese Daten"
        } else {
          status.lagerartikelErrorMsg = error
        }

        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  // async getLagerplaetzeDaten({ commit, dispatch, rootGetters }) {
  //   commit('getLagerplaetzeDatenRequest')

  //   return LagerService.getLagerplaetze()
  //     .then(res => {
  //       commit('getLagerplaetzeDatenSuccess', res.data)
  //     })
  //     .catch(error => {
  //       commit('getLagerplaetzeDatenFailure', error.response.data.message);
  //     })
  // },

  async [ActionTypes.GetLagerzustand]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.lagerError = "";
    status.lagerLoading = true
    dispatch(CommonActionTypes.SetStatus, status);

    return LagerService.getLagerplaetze()
      .then(async (res) => {
        const lagerplaetze: Array<LagerplatzResponse> = res.data
        commit(MutationTypes.GetLagerplaetzeDatenSuccess, lagerplaetze)

        await dispatch(ActionTypes.GetArtikelImLager, undefined)
        await dispatch(ActionTypes.GetLagerpufferDaten, undefined)
        await dispatch(ActionTypes.GetProduktionspufferDaten, undefined)
        
        commit(MutationTypes.GetLagerzustandSuccess, undefined)
        status.lagerLoading = false
        dispatch(CommonActionTypes.SetStatus, status);
      })
      .catch(error => {
        // commit('getLagerzustandFailure', error.response.data.message);
        status.lagerLoading = false
        status.lagerError = "Es ist ein Fehler aufgetreten."
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.UpdateGetLagerzustand]({ commit, dispatch, rootGetters }) {
    
    return LagerService.getLagerplaetze()
    .then(res => {
      const lagerplaetze: Array<LagerplatzResponse> = res.data
      commit(MutationTypes.GetLagerplaetzeDatenSuccess, lagerplaetze)
      
      dispatch(ActionTypes.UpdateGetArtikelImLager, undefined)
        .then(() => {
          commit(MutationTypes.GetLagerzustandSuccess, undefined)
          dispatch(CommonActionTypes.SetStatus, status);
        })
    })
    .catch(error => {
        const status = rootGetters.status;
        // commit('getLagerzustandFailure', error.response.data.message);
        status.lagerError = "Es ist ein Fehler aufgetreten."
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetLagerplatzartenDaten]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;
    status.lagerplatzartenLoading = "";
    status.lagerplatzartenLoading = true;
    dispatch(CommonActionTypes.SetStatus, status);
        
    return LagerService.getLagerplatzarten()
      .then((res) => {
        const result: Array<Lagerplatzart> = res.data
        commit(MutationTypes.GetLagerplatzartenSuccess, result)
      })
      .catch(error => {
        status.lagerplatzartenErrorMsg = error.toString()
        console.log(error);
      })
      .finally(() => {
        status.lagerplatzartenLoading = false;
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetLagerpufferDaten]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;

    return LagerService.getLagerpuffer()
      .then(res => {
        const result: Array<Listeartikel> = res.data
        commit(MutationTypes.GetLagerpufferDatenSuccess, result)
      })
      .catch(error => {
        console.log(error);
        status.lagerError += error.toString()
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },

  async [ActionTypes.GetProduktionspufferDaten]({ commit, dispatch, rootGetters }) {
    const status = rootGetters.status;

    return PpsService.getProduktionspuffer()
      .then(res => {
        const result: Array<Listeppsid> = res.data
        commit(MutationTypes.GetProduktionspufferDatenSuccess, result)
      })
      .catch(error => {
        console.log(error);
        status.lagerError += error.toString()
        dispatch(CommonActionTypes.SetStatus, status);
      })
  },
}
