import { createStore, createLogger } from 'vuex';
// import createPersistedState from 'vuex-persistedstate';

import { store as archiv, ArchivStore, State as ArchivState} from '@/store/modules/Archiv';
import { store as common, CommonStore, State as CommonState} from '@/store/modules/Common';
import { store as bestandskontrolle, BestandskontrolleStore, State as BestandskontrolleState} from '@/store/modules/Lager/Bestandskontrolle';
import { store as lager, LagerStore, State as LagerState} from '@/store/modules/Lager/Lager';
import { store as lagerMaterial, LagerMaterialStore, State as LagerMaterialState} from '@/store/modules/Lager/LagerMaterial';
import { store as mitarbeiter, MitarbeiterStore, State as MitarbeiterState} from '@/store/modules/Lager/Mitarbeiter';
import { store as planung, PlanungStore, State as PlanungState} from '@/store/modules/Lager/Planung';
import { store as printdata, PrintdataStore, State as PrintdataState} from '@/store/modules/Lager/Printdata';
import { store as maschinen, MaschinenStore, State as MaschinenState} from '@/store/modules/Pps/Maschinen';
import { store as ppsMaterial, PpsMaterialStore, State as PpsMaterialState} from '@/store/modules/Pps/Material';
import { store as personal, PersonalStore, State as PersonalState} from '@/store/modules/Pps/Personal';
import { store as produktionsstatus, ProduktionsstatusStore, State as ProduktionsstatusState} from '@/store/modules/Pps/Produktionsstatus';

export type RootState = {
  archiv: ArchivState;
  common: CommonState;
  bestandskontrolle: BestandskontrolleState;
  lager: LagerState;
  lagerMaterial: LagerMaterialState;
  mitarbeiter: MitarbeiterState;
  planung: PlanungState;
  printdata: PrintdataState;
  maschinen: MaschinenState;
  ppsMaterial: PpsMaterialState;
  personal: PersonalState;
  produktionsstatus: ProduktionsstatusState;
};

export type Store = ArchivStore<Pick<RootState, 'archiv'>>
  & CommonStore<Pick<RootState, "common">>
  & BestandskontrolleStore<Pick<RootState, "bestandskontrolle">>
  & LagerStore<Pick<RootState, "lager">>
  & LagerMaterialStore<Pick<RootState, "lagerMaterial">>
  & MitarbeiterStore<Pick<RootState, "mitarbeiter">>
  & PlanungStore<Pick<RootState, "planung">>
  & PrintdataStore<Pick<RootState, "printdata">>
  & MaschinenStore<Pick<RootState, "maschinen">>
  & PpsMaterialStore<Pick<RootState, "ppsMaterial">>
  & PersonalStore<Pick<RootState, "personal">>
  & ProduktionsstatusStore<Pick<RootState, "produktionsstatus">>

const debug = process.env.NODE_ENV !== 'production';
const plugins = debug ? [createLogger({})] : [];

// plugins.push(createPersistedState({ storage: window.sessionStorage }));

export const store = createStore({
  plugins,
  modules: {
    archiv,
    common,
    bestandskontrolle,
    lager,
    lagerMaterial,
    mitarbeiter,
    planung,
    printdata,
    maschinen,
    ppsMaterial,
    personal,
    produktionsstatus
  },
});

export function useStore(): Store {
  return store as Store;
}